import { Observable } from "rxjs";
import * as _ from "lodash";
import NetworkService from "../network/networkService";
import { AgTechQueryParam } from "../enums";
import { helperFunctions } from "../helper";
import { IInventoryAllResponse } from "../models/IInventory.model";
import { IProductAllResponse } from "../models/IProduct.model";

const BASE_URL = "https://apidv.digitalag.farm/trialmaster/v1/";

export const getInventory = (
  queryParams?: Array<{
    key: AgTechQueryParam;
    value: string[];
  }>
): Observable<IInventoryAllResponse> => {
  let url = `${BASE_URL}inventory`;
  for (let param of queryParams || []) {
    url = helperFunctions.addQueryParam(url, param.key, param.value);
  }
  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (res: unknown) => {
        if (res) {
          const response = res as unknown as IInventoryAllResponse;
          subscriber.next(response);
        }
        subscriber.complete();
      },
      (error: Error) => {
        subscriber.error(error);
        console.log("Error while fetching Inventory data", error);
      }
    );
  });
};


export const getInventoryNextPage = (
  nextPageURI: string
): Observable<IInventoryAllResponse> => {
  const url = `${BASE_URL}${nextPageURI}`;
  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (res) => {
        if (res) {
          const response = res as unknown as IInventoryAllResponse;
          subscriber.next(response);
        }
        subscriber.complete();
      },
      (error: any) => {
        subscriber.error(error);
        console.log("Error while fetching Inventory next page ", error);
      }
    );
  });
};

export const getInventoryProduct = (
  queryParams?: Array<{
    key: AgTechQueryParam;
    value: string[];
  }>,
  crop?: string,
  season?: string
): Observable<IProductAllResponse> => {
  let url = `${BASE_URL}inventory/products/${crop}/${season}`;
  for (let param of queryParams || []) {
    url = helperFunctions.addQueryParam(url, param.key, param.value);
  }
  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (res: unknown) => {
        if (res) {
          const response = res as unknown as IProductAllResponse;
          subscriber.next(response);
        }
        subscriber.complete();
      },
      (error: Error) => {
        subscriber.error(error);
        console.log("Error while fetching inventory products", error);
      }
    );
  });
};

export const getInventoryProductNextPage = (
  nextPageURI: string
): Observable<IProductAllResponse> => {
  const url = `${BASE_URL}${nextPageURI}`;
  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (res) => {
        if (res) {
          const response = res as unknown as IProductAllResponse;
          subscriber.next(response);
        }
        subscriber.complete();
      },
      (error: any) => {
        subscriber.error(error);
        console.log("Error while fetching Inventory products next page ", error);
      }
    );
  });
};

export const addInventoryProduct = (requestPayload: any): Observable<any> => {
  let url = `${BASE_URL}inventory/addproducts`;
  return new Observable((subscriber) => {
    NetworkService.post(url, requestPayload).then(
      (res: unknown) => {
        if (res) {
          const response = res as unknown as IProductAllResponse;
          subscriber.next(response);
        }
        subscriber.complete();
      },
      (error: Error) => {
        subscriber.error(error);
        console.log("Error while adding product in inventory", error);
      }
    );
  });
};

export const addVariant = (
  requestPayload: any,
  inventoryId: string
): Observable<any> => {
  let url = `${BASE_URL}inventory/${inventoryId}/addvariants`;
  return new Observable((subscriber) => {
    NetworkService.put(url, requestPayload).then(
      (res: unknown) => {
        if (res) {
          const response = res as unknown as IProductAllResponse;
          subscriber.next(response);
        }
        subscriber.complete();
      },
      (error: Error) => {
        subscriber.error(error);
        console.log("Error while adding variants in inventory", error);
      }
    );
  });
};

export const updateInventory = (requestPayload: any): Observable<any> => {
  let url = `${BASE_URL}inventory`;
  return new Observable((subscriber) => {
    NetworkService.patch(url, requestPayload).then(
      (res: unknown) => {
        subscriber.next(res);
        subscriber.complete();
      },
      (error: Error) => {
        subscriber.error(error);
        console.log("Error while updating the inventory", error);
      }
    );
  });
};

export const deleteInventory = (inventoryId?: string): Observable<any> => {
  let url = `${BASE_URL}inventory/${inventoryId}`;
  return new Observable((subscriber) => {
    NetworkService.delete(url).then(
      (res: unknown) => {
        subscriber.next(res);
        subscriber.complete();
      },
      (error: Error) => {
        subscriber.error(error);
        console.log("Error while deleting the inventory", error);
      }
    );
  });
};

export const getContentLengthForInventory = (
  queryParams?: Array<{
    key: AgTechQueryParam;
    value: string[];
  }>
): Observable<number> => {
  let url = `${BASE_URL}inventory`;
  for (let param of queryParams || []) {
    url = helperFunctions.addQueryParam(url, param.key, param.value);
  }
  return new Observable((subscriber) => {
    NetworkService.head(url).then(
      (response: unknown) => {
        if (response) {
          const { contentLength } = response as { contentLength: number };
          subscriber.next(contentLength);
        }
        subscriber.complete();
      },
      (error: Error) => {
        subscriber.error(error);
        console.log("Error while deleting the inventory", error);
      }
    );
  });
};

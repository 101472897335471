import { Observable } from "rxjs";
import NetworkService from "../network/networkService";
import { AgTechQueryParam } from "../enums";
import {
  IMappingSheetAllResponse,
  IMappingSheetTrial,
  ITrialsLocationsAllResponse,
} from "../models";
import { helperFunctions } from "../helper";

const BASE_URL = "https://apidv.digitalag.farm/trialmaster/v1/triallocation";

export const getMappingSheet = (
  queryParams?: Array<{
    key: AgTechQueryParam;
    value: string[];
  }>,
  seasonId?: string,
  cropId?: string,
  teamId?: string
): Observable<IMappingSheetAllResponse> => {
  const fetchData = (url: string,  accumulatedData: IMappingSheetTrial[] = []): Observable<IMappingSheetAllResponse> => {
    return new Observable((subscriber) => {
      NetworkService.get(url).then(
        (res: unknown) => {
          if (res) {
            const response = res as unknown as IMappingSheetAllResponse;
            const { nextPageUri } = response;
             // Merge the current response data with the accumulated data
            accumulatedData.push(...response.trials);
            
             // If there's another page, continue fetching
            if (nextPageUri) {
              const nextUrl = `${BASE_URL}${nextPageUri}`;
              fetchData(nextUrl, accumulatedData).subscribe({
                next: (nextResponse) => {
                  // Optionally merge responses or pass them through as needed
                  subscriber.next(nextResponse);
                },
                complete: () => {
                  subscriber.complete();
                },
                error: (err) => {
                  subscriber.error(err);
                }
              });
            } else {
            // If no next page, emit the final accumulated result
              subscriber.next({
                ...response,
                trials:  accumulatedData
              });
              console.log(response);
              subscriber.complete();
            }
          } else {
            subscriber.complete();
          }
        },
        (error: Error) => {
          subscriber.error(error);
          console.log("Error while fetching mapping sheet data", error);
        }
      );
    });
  };

  let url = `${BASE_URL}/season/${seasonId}/crop/${cropId}/mapping-sheet/team/${teamId}`;
  for (let param of queryParams || []) {
    url = helperFunctions.addQueryParam(url, param.key, param.value);
  }

  return fetchData(url);
};

export const createMappingSheet = (
  requestPayload: any,
  seasonId: string,
  cropId: string,
  teamId: string
): Observable<IMappingSheetAllResponse> => {
  const url = `${BASE_URL}/season/${seasonId}/crop/${cropId}/mapping-sheet/team/${teamId}`;
  return new Observable((subscriber) => {
    NetworkService.post(url, requestPayload).then(
      (response) => {
        if (response) {
          const res = response as unknown as IMappingSheetAllResponse;
          subscriber.next(res);
        }
        subscriber.complete();
      },
      (error) => {
        subscriber.error(error);
        console.log("Error while creating a mapping sheet", error);
      }
    );
  });
};

export const getTrialsLocations = (
  queryParams?: Array<{
    key: AgTechQueryParam;
    value: string[];
  }>
): Observable<ITrialsLocationsAllResponse> => {
  let url = `${BASE_URL}`;
  for (let param of queryParams || []) {
    url = helperFunctions.addQueryParam(url, param.key, param.value);
  }
  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (res: unknown) => {
        if (res) {
          const response = res as unknown as ITrialsLocationsAllResponse;
          subscriber.next(response);
        }
        subscriber.complete();
      },
      (error: Error) => {
        subscriber.error(error);
        console.log("Error while fetching trial locations", error);
      }
    );
  });
};

export const getTrialsLocationsNextPage = (
  nextPageURI: string
): Observable<ITrialsLocationsAllResponse> => {
  let url = `${BASE_URL}${nextPageURI}`;
  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (res: unknown) => {
        if (res) {
          const response = res as unknown as ITrialsLocationsAllResponse;
          subscriber.next(response);
        }
        subscriber.complete();
      },
      (error: Error) => {
        subscriber.error(error);
        console.log("Error while fetching trial locations", error);
      }
    );
  });
};

export const getTrialsLocationTotalCount = (
  queryParams?: Array<{
    key: AgTechQueryParam;
    value: string[];
  }>
): Observable<number> => {
  let url = `${BASE_URL}`;
  for (let param of queryParams || []) {
    url = helperFunctions.addQueryParam(url, param.key, param.value);
  }

  return new Observable((subscriber) => {
    NetworkService.head(url)
      .then((res: any) => {
        subscriber.next(res?.contentLength ?? 0);
        subscriber.complete();
      })
      .catch((error: Error) => {
        subscriber.error(error);
        subscriber.next(0);
        console.log("Error while fetching total trials locations:", error);
      });
  });
};

export const addReps = (
  requestPayload: any,
  trialLocationId: string
): Observable<IMappingSheetAllResponse> => {
  const url = `${BASE_URL}/mapping-sheet/${trialLocationId}/rep`;
  return new Observable((subscriber) => {
    NetworkService.put(url, requestPayload).then(
      (response) => {
        const res = response as unknown as IMappingSheetAllResponse;
        subscriber.next(res);
        subscriber.complete();
      },
      (error) => {
        subscriber.error(error);
        console.log("Error while Adding new reps", error);
      }
    );
  });
};
export const deleteReps = (
  trialLocationId: string
): Observable<IMappingSheetAllResponse> => {
  const url = `${BASE_URL}/mapping-sheet/${trialLocationId}/rep`;
  return new Observable((subscriber) => {
    NetworkService.delete(url).then(
      (response) => {
        const res = response as unknown as IMappingSheetAllResponse;
        subscriber.next(res);
        subscriber.complete();
      },
      (error) => {
        subscriber.error(error);
        console.log("Error while deleting reps", error);
      }
    );
  });
};

export const deleteMappingSheetTrial = (
  seasonId?: string,
  teamId?: string,
  trialId?: string
): Observable<IMappingSheetAllResponse> => {
  const url = `${BASE_URL}/season/${seasonId}/mapping-sheet/team/${teamId}/trial/${trialId}`;
  return new Observable((subscriber) => {
    NetworkService.delete(url).then(
      (res: unknown) => {
        const response = res as unknown as IMappingSheetAllResponse;
        subscriber.next(response);
        subscriber.complete();
      },
      (error: Error) => {
        subscriber.error(error);
        console.log("Error while deleting trial", error);
      }
    );
  });
};

export const deleteMappingSheetTrialLocation = (
  seasonLocationId?: string,
  cropId?: string,
): Observable<IMappingSheetAllResponse> => {
  const url = `${BASE_URL}/${seasonLocationId}/crop/${cropId}/mapping-sheet`;
  return new Observable((subscriber) => {
    NetworkService.delete(url).then(
      (res: unknown) => {
        const response = res as unknown as IMappingSheetAllResponse;
        subscriber.next(response);
        subscriber.complete();
      },
      (error: Error) => {
        subscriber.error(error);
        console.log("Error while deleting location", error);
      }
    );
  });
};

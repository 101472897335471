import { Observable } from "rxjs";
import NetworkService from "../network/networkService";
import { AgTechQueryParam } from "../enums";
import { helperFunctions } from "../helper";
import { ITeams, ITeamsAllResponse } from "../models/ITeam.model";
const BASE_URL = "https://apidv.digitalag.farm/trialauth/v1/";
export const getTeams = (
  queryParams?: Array<{
    key: AgTechQueryParam;
    value: string[];
  }>
): Observable<ITeamsAllResponse> => {
  let url = `${BASE_URL}team`;
  for (let param of queryParams || []) {
    url = helperFunctions.addQueryParam(url, param.key, param.value);
  }
  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (res: unknown) => {
        if (res) {
          const response = res as unknown as ITeamsAllResponse;
          subscriber.next(response);
        }
        subscriber.complete();
      },
      (error: Error) => {
        subscriber.error(error);
        console.log("Error while fetching crops master data", error);
      }
    );
  });
};

export const createTeam = (
  requestPayload: any
): Observable<ITeamsAllResponse> => {
  let url = `${BASE_URL}team`;
  return new Observable((subscriber) => {
    NetworkService.post(url, requestPayload).then(
      (res: unknown) => {
        if (res) {
          const response = res as unknown as ITeamsAllResponse;
          subscriber.next(response);
        }
        subscriber.complete();
      },
      (error: Error) => {
        subscriber.error(error);
        console.log("Error while fetching crops master data", error);
      }
    );
  });
};

export const getTeamsNextPage = (
  nextPageURI: string
): Observable<ITeamsAllResponse> => {
  const url = `${BASE_URL}${nextPageURI}`;

  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (res) => {
        if (res) {
          const response = res as unknown as ITeamsAllResponse;
          subscriber.next(response);
        }
        subscriber.complete();
      },
      (error: any) => {
        subscriber.error(error);
        console.log("Error while fetching seasons master data", error);
      }
    );
  });
};

export const getContentLengthForTeams = (
  queryParams?: Array<{
    key: AgTechQueryParam;
    value: string[];
  }>
): Observable<number> => {
  let url = `${BASE_URL}team`;
  for (let param of queryParams || []) {
    url = helperFunctions.addQueryParam(url, param.key, param.value);
  }

  return new Observable((subscriber) => {
    NetworkService.head(url)
      .then((res: any) => {
        subscriber.next(res?.contentLength ?? 0);
        subscriber.complete();
      })
      .catch((error: Error) => {
        subscriber.error(error);
        subscriber.next(0);
        console.log("Error while fetching crops master data:", error);
      });
  });
};

export const updateTeam = (
  teamsPayload: any,
  teamId?: string
): Observable<ITeams> => {
  let url = `${BASE_URL}team/${teamId}`;
  return new Observable((subscriber) => {
    NetworkService.put(url, teamsPayload).then(
      (res) => {
        const response = res as unknown as ITeams;
        subscriber.next(response);
        subscriber.complete();
      },
      (error) => {
        subscriber.error(error);
        console.log("Error while updating field", error);
      }
    );
  });
};

export const patchUpdateTeam = (
  teamsPayload: any,
  teamId?: string
): Observable<ITeams> => {
  let url = `${BASE_URL}team/${teamId}`;
  return new Observable((subscriber) => {
    NetworkService.patch(url, teamsPayload).then(
      (res) => {
        const response = res as unknown as ITeams;
        subscriber.next(response);
        subscriber.complete();
      },
      (error) => {
        subscriber.error(error);
        console.log("Error while updating field", error);
      }
    );
  });
};

export const deleteTeam = (teamId?: string): Observable<{}> => {
  let url = `${BASE_URL}team/${teamId}`;
  return new Observable((subscriber) => {
    NetworkService.delete(url).then(
      (res) => {
        subscriber.next(res);
        subscriber.complete();
      },
      (error) => {
        subscriber.error(error);
        console.log("Error while deleting team", error);
      }
    );
  });
};

import { IPrimaryTillageAllResponse } from "../models/IPrimaryTillage.model";
import { AgTechQueryParam } from "../enums";
import { helperFunctions } from "../helper";
import { ITrialLocation, ITrialLocationAllResponse } from "../models/ITrialLocation.model";
import NetworkService from "../network/networkService";
import { Observable } from "rxjs";
import { IApplicationStageAllResponse } from "../models/IApplicationStage.model";
import { ICropStageAllResponse } from "../models/ICropStage.model";
import { ILocationBookTrialsAllResponse } from "../models";

const BASE_URL = "https://apidv.digitalag.farm/trialmaster/v1/";

export const getTrialLocation = (
  queryParams?: Array<{
    key: AgTechQueryParam;
    value: string[];
  }>
): Observable<ITrialLocationAllResponse> => {
  let url = `${BASE_URL}triallocation`;
  for (let param of queryParams || []) {
    url = helperFunctions.addQueryParam(url, param.key, param.value);
  }
  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (res: unknown) => {
        if (res) {
          const response = res as unknown as ITrialLocationAllResponse;
          subscriber.next(response);
        }
        subscriber.complete();
      },
      (error: Error) => {
        subscriber.error(error);
        console.log("Error while fetching trialLocation data", error);
      }
    );
  });
};
export const getTrialLocationNextPage = (
  nextPageURI: string
): Observable<ITrialLocationAllResponse> => {
  let url = `${BASE_URL}${nextPageURI}`;

  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (locationResponse) => {
        if (locationResponse) {
          const locationsResponse =
            locationResponse as unknown as ITrialLocationAllResponse;

          subscriber.next(locationsResponse);
        }

        subscriber.complete();
      },
      (error: any) => {
        subscriber.error(error);
        console.log("Error while fetching Locations master data", error);
      }
    );
  });
};

export const getTrialLocationById = (
  id?: string,
  queryParams?: Array<{
    key: AgTechQueryParam;
    value: string[];
  }>
): Observable<ITrialLocation> => {
  let url = `${BASE_URL}triallocation/${id}`;
  for (let param of queryParams || []) {
    url = helperFunctions.addQueryParam(url, param.key, param.value);
  }
  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (res: unknown) => {
        if (res) {
          const response = res as unknown as ITrialLocation;
          subscriber.next(response);
        }
        subscriber.complete();
      },
      (error: Error) => {
        subscriber.error(error);
        console.log("Error while fetching trialLocation data", error);
      }
    );
  });
};


export const getTrialByLocationId = (
  id?: string,
  queryParams?: Array<{
    key: AgTechQueryParam;
    value: string[];
  }>
): Observable<ILocationBookTrialsAllResponse> => {
  let url = `${BASE_URL}triallocation/${id}/trials`;
  for (let param of queryParams || []) {
    url = helperFunctions.addQueryParam(url, param.key, param.value);
  }
  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (res: unknown) => {
        if (res) {
          const response = res as unknown as ILocationBookTrialsAllResponse;
          subscriber.next(response);
        }
        subscriber.complete();
      },
      (error: Error) => {
        subscriber.error(error);
        console.log("Error while fetching user data", error);
      }
    );
  });
};


export const updateTrialLocationUsingPatch = (
  requestPayload: any,
  trialLocationId?: string
): Observable<any> => {
  let url = `${BASE_URL}triallocation/${trialLocationId}`;

  return new Observable((subscriber) => {
    NetworkService.patch(url, requestPayload).then(
      (res) => {
        const response = res as unknown as ITrialLocation;
        subscriber.next(response);
        subscriber.complete();
      },
      (error) => {
        subscriber.error(error);
        console.log("Error while updating trialfield", error);
      }
    );
  });
};



export const getPrimaryTillage = (): Observable<IPrimaryTillageAllResponse> => {
  let url = `${BASE_URL}tillagetype`;
  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (res: unknown) => {
        if (res) {
          const response = res as unknown as IPrimaryTillageAllResponse;
          subscriber.next(response);
        }
        subscriber.complete();
      },
      (error: Error) => {
        subscriber.error(error);
        console.log("Error while fetching trialLocation data", error);
      }
    );
  });
};
export const getApplicationStageList =
  (): Observable<IApplicationStageAllResponse> => {
    let url = `${BASE_URL}applicationtype`;
    return new Observable((subscriber) => {
      NetworkService.get(url).then(
        (res: unknown) => {
          if (res) {
            const response = res as unknown as IApplicationStageAllResponse;
            subscriber.next(response);
          }
          subscriber.complete();
        },
        (error: Error) => {
          subscriber.error(error);
          console.log("Error while fetching trialLocation data", error);
        }
      );
    });
  };
export const getCropStage = (): Observable<ICropStageAllResponse> => {
  let url = `${BASE_URL}cropstage`;
  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (res: unknown) => {
        if (res) {
          const response = res as unknown as ICropStageAllResponse;

          subscriber.next(response);
        }
        subscriber.complete();
      },
      (error: Error) => {
        subscriber.error(error);
        console.log("Error while fetching trialLocation data", error);
      }
    );
  });
};
export const createTrialLocation = (requestPayload: any): Observable<any> => {
  let url = `${BASE_URL}triallocation`;

  return new Observable((subscriber) => {
    NetworkService.post(url, requestPayload).then(
      (trialResponse) => {
        if (trialResponse) {
          const trialsResponse =
            trialResponse as unknown as ITrialLocationAllResponse;
          subscriber.next(trialsResponse);
        }
        subscriber.complete();
      },
      (error) => {
        subscriber.error(error);
        console.log("Error while creating a Trial", error);
      }
    );
  });
};
export const deleteTrialLocation = (trialLocationId: string): Observable<any> => {
  let url = `${BASE_URL}/triallocation/${trialLocationId}`;

  return new Observable((subscriber) => {
    NetworkService.delete(url).then(
      (response) => {
        subscriber.next(response);
        subscriber.complete();
      },
      (error) => {
        subscriber.error(error);
        console.log("Error while updating seasonfield", error);
      }
    );
  });
};

export const getContentLengthForTrialLocation = (
  queryParams?: Array<{
    key: AgTechQueryParam;
    value: string[];
  }>
): Observable<number> => {
  let url = `${BASE_URL}/triallocation`;
  for (let param of queryParams || []) {
    url = helperFunctions.addQueryParam(url, param.key, param.value);
  }

  return new Observable((subscriber) => {
    NetworkService.head(url)
      .then((res: any) => {
        subscriber.next(res?.contentLength ?? 0);
        subscriber.complete();
      })
      .catch((error: Error) => {
        subscriber.error(error);
        subscriber.next(0);
        console.log("Error while fetching crops master data:", error);
      });
  });
};

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import type { Action, ThunkAction } from '@reduxjs/toolkit'
import appDetailReducer from './AppDetails/AppDetailsSlice';
import trailsReducer from './Trials/TrialsSlice';
import trialLocationReducer  from "./TrialLocation/TrialLocationSlice";
import LocationBookReducer  from "./LocationBook/LocationBookSlice";


import {  persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
const persistConfig = {
  key: 'root',
  storage,
};
const rootReducer = combineReducers({
  appDetails: appDetailReducer, // Your reducers go here
  trials:trailsReducer,
  trialLocation:trialLocationReducer,
  locationBook: LocationBookReducer
});
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
})

// Infer the type of `store`
export type AppStore = typeof store
export const persistor = persistStore(store);
export type RootState = ReturnType<AppStore['getState']>
// Infer the `AppDispatch` type from the store itself
export type AppDispatch = AppStore['dispatch']
// Define a reusable type describing thunk functions
export type AppThunk<ThunkReturnType = void> = ThunkAction<
  ThunkReturnType,
  RootState,
  unknown,
  Action
>
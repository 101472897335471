export const APP_MESSAGES = {
  COMMON_MSG: {
    SEARCH_HINT_TEXT: "Press enter to search",
  },
  SEASON_ADDED: "Season Added Successfully.",
  SEASON_UPDATE: "Season Updated Successfully.",
  SEASON_ALREADY_EXIST: "Season Already Exists.",
  CROP_ADDED: "Crop Added Successfully.",
  CROP_UPDATE: "Crop Updated Successfully.",
  CROP_ALREADY_EXIST: "Crop Already Exists.",
  LOCATION_ADDED: "Location Added Successfully.",
  LOCATION_UPDATE: "Location Updated Successfully.",
  LOCATION_ALREADY_EXIST: "Location Already Exists.",
  PRODUCT_ADDED: "Product Added Successfully.",
  PRODUCT_ALREADY_EXIST: "Product Already Exists.",
  PRODUCT_UPDATE: "Product Updated Successfully.",
  PRODUCT_DELETED: "Product Deleted Successfully.",
  USER_ADDED: "User Added Successfully.",
  USER_UPDATE: "User Updated Successfully.",
  USER_ALREADY_EXIST: "User Already Exists.",
  USER_DELETED: "User Deleted Successfully.",
  USER_DELETE_FAILURE:"Failed to delete user",
  TEAM_ADDED:'Team Added Successfully.',
  TEAM_ALREADY_EXIST:'Team Already Exists.',
  TEAM_UPDATE:'Team Updated Successfully.',
  TEAM_DELETED:'Team Deleted Successfully.',
  ADD_INVENTORY_PRODUCT:'Product Added Successfully.',
  TREATMENT_DELETED:"Treatment Deleted Successfully",
  ADDED_VARIENTS:'Variants Added Successfully.',
  INVENTORY_UPDATE:'Inventory Updated Successfully.',
  INVENTORY_DELETED:'Inventory Deleted Successfully.',
  TRIAL_PUBLISH_SUCCESS:'Trial Published Successfully.',
  TRIAL_PUBLISH_FAILURE:'Trial Published failed. Please try after some time.',
  TRIAL_CREATE_SUCCESS:'Trial Created Successfully.',
  TRIAL_UPDATE_SUCCESS:'Trial Updated Successfully.',
  TRIAL_ALREADY_EXIST:'Trial Already Exists.',
  TRIALLOCATION_CREATE_SUCCESS:'TrialLocation Created Successfully.',
  TRIALLOCATION_DELETED:'TrialLocation Deleted Successfully.',
  TRIALLOCATION_DELETE_FAILURE:"Failed to delete TrialLocation",
  TRIAL_PRODUCT_UPDATE_SUCCESS:'Products Updated Successfully.',
  MAPPING_SHEET_ALREADY_EXIST: "Mapping Sheet Already Exists.",
  MAPPING_SHEET_NO_CHANGE_EXIST: "No New Changes To Save.",
  MAPPING_SHEET_CREATE_SUCCESS: "Mapping Sheet Created Successfully.",
  TRIAL_REP_CREATE_SUCCESS:'Rep Created Successfully.',
  DELETE_REP_SUCCESS:'Rep Deleted Successfully.',
  REP_INFO_UPDATE:"RepInfo Updated Succcessfully",
  FIELD_JOURNAL_UPDATE_SUCCESS:'Field Journal is Updated Successfully.',
  MAPPING_SHEET_TRIAL_DELETE_SUCCESS: "Mapping Sheet Trial Deleted Successfully.",
  MAPPING_SHEET_LOCATION_DELETE_SUCCESS: "Mapping Sheet Location Deleted Successfully.",
};

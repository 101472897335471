import { Observable } from "rxjs";
import NetworkService from "../network/networkService";
import { helperFunctions } from "../helper/helper";
import { AgTechQueryParam } from "../enums/AgTechQueryParams.enum";
import {
  IProduct,
  IProductAllResponse,
  IProductAttributesAllResponse,
  IProductCategoryAllResponse,
} from "../models/IProduct.model";

const BASE_URL = "https://apidv.digitalag.farm/trialmasterdata/v1";

export const getProducts = (
  queryParams?: Array<{
    key: AgTechQueryParam;
    value: string[];
  }>
): Observable<IProductAllResponse> => {
  let url = `${BASE_URL}/product`;
  for (let param of queryParams || []) {
    url = helperFunctions.addQueryParam(url, param.key, param.value);
  }
  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (productResponse: unknown) => {
        if (productResponse) {
          const productsResponse =
            productResponse as unknown as IProductAllResponse;
          subscriber.next(productsResponse);
        }
        subscriber.complete();
      },
      (error: any) => {
        subscriber.error(error);
        console.log("Error while fetching products master data", error);
      }
    );
  });
};

export const getProductsNextPage = (
  nextPageURI: string
): Observable<IProductAllResponse> => {
  let url = `${BASE_URL}${nextPageURI}`;

  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (productResponse) => {
        if (productResponse) {
          const productsResponse =
            productResponse as unknown as IProductAllResponse;
          subscriber.next(productsResponse);
        }
        subscriber.complete();
      },
      (error: any) => {
        subscriber.error(error);
        console.log("Error while fetching products master data", error);
      }
    );
  });
};

export const createProduct = (requestPayload: any): Observable<any> => {
  let url = `${BASE_URL}/product`;

  return new Observable((subscriber) => {
    NetworkService.post(url, requestPayload).then(
      (response) => {
        subscriber.next(response);
        subscriber.complete();
      },
      (error) => {
        subscriber.error(error);
        console.log("Error while creating a product", error);
      }
    );
  });
};

export const getContentLengthForProduct = (
  queryParams?: Array<{
    key: AgTechQueryParam;
    value: string[];
  }>
): Observable<number> => {
  let url = `${BASE_URL}/product`;
  for (let param of queryParams || []) {
    url = helperFunctions.addQueryParam(url, param.key, param.value);
  }

  return new Observable((subscriber) => {
    NetworkService.head(url)
      .then((res: any) => {
        subscriber.next(res?.contentLength ?? 0);
        subscriber.complete();
      })
      .catch((error: Error) => {
        subscriber.error(error);
        subscriber.next(0);
        console.log("Error while fetching crops master data:", error);
      });
  });
};

export const updateProduct = (
  productPayload: any,
  productId?: string
): Observable<any> => {
  let url = `${BASE_URL}/product/${productId}`;

  return new Observable((subscriber) => {
    NetworkService.patch(url, productPayload).then(
      (response) => {
        subscriber.next(response);
        subscriber.complete();
      },
      (error) => {
        subscriber.error(error);
        console.log("Error while updating field", error);
      }
    );
  });
};

export const getProductAttributes = (
  queryParams?: Array<{
    key: AgTechQueryParam;
    value: string[];
  }>
): Observable<IProductAttributesAllResponse> => {
  let url = `${BASE_URL}/ProductAttribute`;
  for (let param of queryParams || []) {
    url = helperFunctions.addQueryParam(url, param.key, param.value);
  }
  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (productResponse: unknown) => {
        if (productResponse) {
          const productsResponse =
            productResponse as unknown as IProductAttributesAllResponse;
          subscriber.next(productsResponse);
        }
        subscriber.complete();
      },
      (error: any) => {
        subscriber.error(error);
        console.log(
          "Error while fetching products attribute master data",
          error
        );
      }
    );
  });
};

export const getProductCategory = (
  queryParams?: Array<{
    key: AgTechQueryParam;
    value: string[];
  }>
): Observable<IProductCategoryAllResponse> => {
  let url = `${BASE_URL}/ProductCategory`;

  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (productResponse: unknown) => {
        if (productResponse) {
          const productsCatResponse =
            productResponse as unknown as IProductCategoryAllResponse;
          subscriber.next(productsCatResponse);
        }
        subscriber.complete();
      },
      (error: any) => {
        subscriber.error(error);
        console.log(
          "Error while fetching products attribute master data",
          error
        );
      }
    );
  });
};

export const deleteProduct = (productId?: string): Observable<IProduct> => {
  let url = `${BASE_URL}/product/${productId}`;

  return new Observable((subscriber) => {
    NetworkService.delete(url).then(
      (productResponse: unknown) => {
        const productsCatResponse = productResponse as unknown as IProduct;
        subscriber.next(productsCatResponse);
        subscriber.complete();
      },
      (error: any) => {
        subscriber.error(error);
        console.log("Error while deleting products master data", error);
      }
    );
  });
};

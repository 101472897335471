import { Observable } from "rxjs";
import * as _ from "lodash";
import NetworkService from "../network/networkService";
import { ICropAllResponse } from "../models/ICrop.model";
import { AgTechQueryParam } from "../enums";
import { helperFunctions } from "../helper";

const BASE_URL = "https://apidv.digitalag.farm/trialmasterdata/v1/";

export const getCrops = (
  queryParams?: Array<{
    key: AgTechQueryParam;
    value: string[];
  }>
): Observable<ICropAllResponse> => {
  let url = `${BASE_URL}crop`;
  for (let param of queryParams || []) {
    url = helperFunctions.addQueryParam(url, param.key, param.value);
  }
  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (res: unknown) => {
        if (res) {
          const response = res as unknown as ICropAllResponse;
          subscriber.next(response);
        }
        subscriber.complete();
      },
      (error: Error) => {
        subscriber.error(error);
        console.log("Error while fetching crops master data", error);
      }
    );
  });
};

export const createCrop = (
  requestPayload: any
): Observable<ICropAllResponse> => {
  let url = `${BASE_URL}/crop`;
  return new Observable((subscriber) => {
    NetworkService.post(url, requestPayload).then(
      (res: unknown) => {
        if (res) {
          const response = res as unknown as ICropAllResponse;
          const sortedCrops = _.sortBy(response?.values ?? [], "name");
          const cropsData = { ...response, crops: sortedCrops };
          subscriber.next(cropsData);
        }
        subscriber.complete();
      },
      (error: Error) => {
        subscriber.error(error);
        console.log("Error while fetching crops master data", error);
      }
    );
  });
};

export const getContentLengthForCrops = (
  queryParams?: Array<{
    key: AgTechQueryParam;
    value: string[];
  }>
): Observable<number> => {
  let url = `${BASE_URL}crop`;
  for (let param of queryParams || []) {
    url = helperFunctions.addQueryParam(url, param.key, param.value);
  }

  return new Observable((subscriber) => {
    NetworkService.head(url)
      .then((res: any) => {
        subscriber.next(res?.contentLength ?? 0);
        subscriber.complete();
      })
      .catch((error: Error) => {
        subscriber.error(error);
        console.log("Error while fetching crops master data:", error);
      });
  });
};

export const getCropsNextPage = (
  nextPageURI: string
): Observable<ICropAllResponse> => {
  const url = `${BASE_URL}${nextPageURI}`;

  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (res) => {
        if (res) {
          const response = res as unknown as ICropAllResponse;
          const sortedCrops = _.sortBy(response?.crops ?? [], "name");
          const cropsData = { ...response, crops: sortedCrops };
          subscriber.next(cropsData);
        }
        subscriber.complete();
      },
      (error: any) => {
        subscriber.error(error);
        console.log("Error while fetching seasons master data", error);
      }
    );
  });
};

import { Observable } from "rxjs";
import NetworkService from "../network/networkService";
import { helperFunctions } from "../helper";
import { AgTechQueryParam } from "../enums";
import { ITreatmentInfoResponse } from "../models";

const BASE_URL = "https://apidv.digitalag.farm/trialmaster/v1";

export const getTreatmentInfo = (
  queryParams: {
    key: AgTechQueryParam;
    value: string[];
  }[],
  trialId: string | undefined
): Observable<ITreatmentInfoResponse> => {
  let url = `${BASE_URL}/triallocation/${trialId}/treatments`;
  for (let param of queryParams || []) {
    url = helperFunctions.addQueryParam(url, param.key, param.value);
  }
  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (res: unknown) => {
        if (res) {
          const response = res as unknown as ITreatmentInfoResponse;
          subscriber.next(response);
        }
        subscriber.complete();
      },
      (error: Error) => {
        subscriber.error(error);
        console.log("Error while fetching treatment info data", error);
      }
    );
  });
};

export const getTreatmentInfoNextPage = (nextPageUri: string): Observable<ITreatmentInfoResponse> => {
  let url = `${BASE_URL}${nextPageUri}`;
  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (res) => {
        if (res) {
          const trialResponse = res as unknown as ITreatmentInfoResponse;
          subscriber.next(trialResponse);
        }
        subscriber.complete();
      },
      (error: any) => {
        subscriber.error(error);
        console.log("Error while fetching Locations master data", error);
      }
    );
  });
};

export const getTrialTreatmentLength = (
  queryParams: {
    key: AgTechQueryParam;
    value: string[];
  }[],
  trialId: string | undefined
): Observable<number> => {
  let url = `${BASE_URL}/triallocation/${trialId}/treatments`;
  for (let param of queryParams || []) {
    url = helperFunctions.addQueryParam(url, param.key, param.value);
  }
  return new Observable((subscriber) => {
    NetworkService.head(url)
      .then((res: any) => {
        subscriber.next(res?.contentLength ?? 0);
        subscriber.complete();
      })
      .catch((error: Error) => {
        subscriber.error(error);
        subscriber.next(0);
        console.log("Error while fetching crops master data:", error);
      });
  });
};

import { createSlice } from "@reduxjs/toolkit";
import { ICrop } from "packages/lib-utils/src/models";
import { ISeason } from "packages/lib-utils/src/models/ISeason.model";

// Define the TS type for the counter slice's state
export interface AppDetailsState {
  filters: {
    crops: ICrop | null;
    season: ISeason | null;
  };
  pageDetail:{
    title:string,
    status?:string,
    breadCrumbNavigations?:{
      path:string,
      title:string
    }[],
  }
}

// Define the initial value for the slice state
const initialState: AppDetailsState = {
  filters: {
    crops: null,
    season: null,
  },
  pageDetail:{
    title:'',
    status:'',
  }
};
export const appDetailsSlice = createSlice({
  name: "appDetails",
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    resetFilter: (state) => {
      state.filters = initialState.filters;
    },
    setPageDetail: (state, action) => {
      state.pageDetail = action.payload;
    },
  },
});

// Export the generated action creators for use in components
export const { setFilters, resetFilter, setPageDetail } = appDetailsSlice.actions;

// Export the slice reducer for use in the store configuration
export default appDetailsSlice.reducer;

import { Observable } from "rxjs";
import * as _ from "lodash";
import NetworkService from "../network/networkService";
import { AgTechQueryParam } from "../enums";
import { helperFunctions } from "../helper";
import { IAttributeResponse } from "../models/IAdditionalAttribute.model";

const BASE_URL = "https://apidv.digitalag.farm/trialmaster/v1/";

export const getAttribute = (
  queryParams?: Array<{
    key: AgTechQueryParam;
    value: string[];
  }>
): Observable<IAttributeResponse > => {
  let url = `${BASE_URL}step`;
  for (let param of queryParams || []) {
    url = helperFunctions.addQueryParam(url, param.key, param.value);
  }
  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (res: unknown) => {
        if (res) {
          const response = res as unknown as IAttributeResponse ;
          subscriber.next(response);
        }
        subscriber.complete();
      },
      (error: Error) => {
        subscriber.error(error);
        console.log("Error while fetching user data", error);
      }
    );
  });
};
import { AgTechQueryParam } from "../enums";
import { helperFunctions } from "../helper";
import NetworkService from "../network/networkService";
import { Observable } from "rxjs";
import { IFieldJournalAllResponse } from "../models";

const BASE_URL = "https://apidv.digitalag.farm/trialmaster/v1/";

// const url = `${BASE_URL}/season/${seasonId}/crop/${cropId}/mapping-sheet/team/${teamId}`;
export const getFieldJournal = (
  answerPlotLocationId: string,
  // trialId: number,
  queryParams?: Array<{
    key: AgTechQueryParam;
    value: string[];
  }>
): Observable<IFieldJournalAllResponse> => {
  let url = `${BASE_URL}triallocation/${answerPlotLocationId}/field-journal`;
  for (let param of queryParams || []) {
    url = helperFunctions.addQueryParam(url, param.key, param.value);
  }
  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (res: unknown) => {
        if (res) {
          const response = res as unknown as IFieldJournalAllResponse;
          subscriber.next(response);
        }
        subscriber.complete();
      },
      (error: Error) => {
        subscriber.error(error);
        console.log("Error while fetching field Journal data", error);
      }
    );
  });
};
export const getFieldJournalNextPage = (
  nextPageURI: string
): Observable<IFieldJournalAllResponse> => {
  let url = `${BASE_URL}${nextPageURI}`;

  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (locationResponse) => {
        if (locationResponse) {
          const locationsResponse =
            locationResponse as unknown as IFieldJournalAllResponse;

          subscriber.next(locationsResponse);
        }

        subscriber.complete();
      },
      (error: any) => {
        subscriber.error(error);
        console.log("Error while fetching Locations field Journal data", error);
      }
    );
  });
};

export const updateFieldJournal = (
  answerPlotLocationId: string,
  // trialId: number,
  requestPayload?: any
): Observable<any> => {
  let url = `${BASE_URL}triallocation/${answerPlotLocationId}/field-journal`;
  return new Observable((subscriber) => {
    NetworkService.patch(url, requestPayload).then(
      (res: unknown) => {
        subscriber.next(res);
        subscriber.complete();
      },
      (error: Error) => {
        subscriber.error(error);
        console.log("Error while updating the Field Journal", error);
      }
    );
  });
};


export const getContentLengthForFieldJournal = (
  answerPlotLocationId: string,
  trialId: number,
  queryParams?: Array<{
    key: AgTechQueryParam;
    value: string[];
  }>
): Observable<number> => {
  let url = `${BASE_URL}triallocation/${answerPlotLocationId}/field-journal`;
  for (let param of queryParams || []) {
    url = helperFunctions.addQueryParam(url, param.key, param.value);
  }

  return new Observable((subscriber) => {
    NetworkService.head(url)
      .then((res: any) => {
        console.log(res,"head api response");
        subscriber.next(res?.contentLength ?? 0);
        subscriber.complete();
      })
      .catch((error: Error) => {
        subscriber.error(error);
        subscriber.next(0);
        console.log("Error while fetching Field Journal Data:", error);
      });
  });
};

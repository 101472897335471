import APPCONFIG from "../config/appConfig";
const BASE_URL = "https://apidv.digitalag.farm";

export const URLConfig = {
  getCropsMasterDataURl() {
    return `${BASE_URL}/masterdata/crops`;  // TO DO : update it later on
  },

  getSeasonsMasterDataURl() {
    return `${BASE_URL}/trialmasterdata/v1/season`;
  },

  getSeasonsNextPageURl(nextPageURI: string) {
    return `${BASE_URL}/trialmasterdata/v1/${nextPageURI}`;
  },
};

import { Observable } from "rxjs";
import NetworkService from "../network/networkService";
import * as _ from "lodash";
import { ISeason, ISeasonAllResponse } from "../models/ISeason.model";
import { helperFunctions } from "../helper/helper";
import { AgTechQueryParam } from "../enums/AgTechQueryParams.enum";
import { URLConfig } from "../network/urlStore";

export const getSeasons = (
  queryParams?: Array<{
    key: AgTechQueryParam;
    value: string[];
  }>
): Observable<ISeasonAllResponse> => {
  let url = URLConfig.getSeasonsMasterDataURl();
  for (let param of queryParams || []) {
    url = helperFunctions.addQueryParam(url, param.key, param.value);
  }
  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (seasonResponse: unknown) => {
        if (seasonResponse) {
          const seasonsResponse =
            seasonResponse as unknown as ISeasonAllResponse;
          let seasonList: ISeason[] = seasonsResponse.seasons.filter(
            (x: any) => new Date(x.startDay) <= new Date()
          );
          seasonList = seasonList.map((season: any) => ({
            ...season,
            year: season.code,
          })); // mapping code to year

          seasonsResponse.seasons = seasonList;
          subscriber.next(seasonsResponse);
        }
        subscriber.complete();
      },
      (error: any) => {
        subscriber.error(error);
        console.log("Error while fetching seasons master data", error);
      }
    );
  });
};

export const getSeasonsNextPage = (
  nextPageURI: string
): Observable<ISeasonAllResponse> => {
  let url = URLConfig.getSeasonsNextPageURl(nextPageURI);

  // let url = `https://apidv.digitalag.farm/trialmasterdata/v1${nextPageURI}`;
  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (seasonResponse) => {
        if (seasonResponse) {
          const seasonsResponse =
            seasonResponse as unknown as ISeasonAllResponse;
          let seasonList: ISeason[] = seasonsResponse.seasons.filter(
            (x: any) => new Date(x.startDay) <= new Date()
          );
          seasonList = seasonList.map((season: any) => ({
            ...season,
            year: season.code,
          })); // mapping code to year

          seasonsResponse.seasons = seasonList;
          subscriber.next(seasonsResponse);
        }
        subscriber.complete();
      },
      (error: any) => {
        subscriber.error(error);
        console.log("Error while fetching seasons master data", error);
      }
    );
  });
};

export const getContentLengthForSeason = (
  queryParams?: Array<{
    key: AgTechQueryParam;
    value: string[];
  }>
): Observable<number> => {
  let url = URLConfig.getSeasonsMasterDataURl();
  // let url = 'https://apidv.digitalag.farm/trialmasterdata/v1' + '/season';
  for (let param of queryParams || []) {
    url = helperFunctions.addQueryParam(url, param.key, param.value);
  }

  return new Observable((subscriber) => {
    NetworkService.head(url)
      .then((res: any) => {
        subscriber.next(res?.contentLength ?? 0);
        subscriber.complete();
      })
      .catch((error: Error) => {
        subscriber.error(error);
        subscriber.next(0);
        console.log("Error while fetching crops master data:", error);
      });
  });
};

export const createSeason = (requestPayload: any): Observable<any> => {
  let url = URLConfig.getSeasonsMasterDataURl();
  return new Observable((subscriber) => {
    NetworkService.post(url, requestPayload).then(
      (response) => {
        subscriber.next(response);
        subscriber.complete();
      },
      (error) => {
        subscriber.error(error);
        console.log("Error while creating a season", error);
      }
    );
  });
};

export const updateSeason = (
  seasonPayload: any,
  seasonId?: string
): Observable<any> => {
  let url = URLConfig.getSeasonsMasterDataURl();
  url = url + "/seasonId";

  return new Observable((subscriber) => {
    NetworkService.patch(url, seasonPayload).then(
      (response) => {
        subscriber.next(response);
        subscriber.complete();
      },
      (error) => {
        subscriber.error(error);
        console.log("Error while updating seasonfield", error);
      }
    );
  });
};

import { Observable } from "rxjs";
import NetworkService from "../network/networkService";
import * as _ from "lodash";
import { helperFunctions } from "../helper/helper";
import { AgTechQueryParam } from "../enums/AgTechQueryParams.enum";
import { ILocationRepInfoAllResponse } from "../models/ILocationRepInfo.model";

const BASE_URL = `https://apidv.digitalag.farm/trialmaster/v1/`;

export const getRepInfo = (
  id: string | undefined,
  queryParams: Array<{
    key: AgTechQueryParam;
    value: string[];
  }>
): Observable<ILocationRepInfoAllResponse> => {
  let url = `${BASE_URL}triallocation/${id}/rep`;

  for (let param of queryParams || []) {
    url = helperFunctions.addQueryParam(url, param.key, param.value);
  }
  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (repInfoResponse: unknown) => {
        if (repInfoResponse) {
          const res =
            repInfoResponse as unknown as ILocationRepInfoAllResponse;

          subscriber.next(res);
        }
        subscriber.complete();
      },
      (error: any) => {
        subscriber.error(error);
        console.log("Error while fetching Locations master data", error);
      }
    );
  });
};

export const getRepInfoNextPage = (
  nextPageURI: string
): Observable<ILocationRepInfoAllResponse> => {
  let url = `${BASE_URL}${nextPageURI}`;

  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (repInfoResponse: unknown) => {
        if (repInfoResponse) {
          const res =
            repInfoResponse as unknown as ILocationRepInfoAllResponse;

          subscriber.next(res);
        }
        subscriber.complete();
      },
      (error: any) => {
        subscriber.error(error);
        console.log("Error while fetching Locations master data", error);
      }
    );
  });
};

export const getContentLengthForRepInfo = (
  id: string | undefined,
  queryParams?: Array<{
    key: AgTechQueryParam;
    value: string[];
  }>
): Observable<number> => {
  let url = `${BASE_URL}triallocation/${id}/rep`;
  for (let param of queryParams || []) {
    url = helperFunctions.addQueryParam(url, param.key, param.value);
  }

  return new Observable((subscriber) => {
    NetworkService.head(url)
      .then((res: any) => {
        subscriber.next(res?.contentLength ?? 0);
        subscriber.complete();
      })
      .catch((error: Error) => {
        subscriber.error(error);
        subscriber.next(0);
        console.log("Error while fetching crops master data:", error);
      });
  });
};
export const updateRepInfo = (
  id: string | undefined,
  repInfoPayload: any
): Observable<number> => {
  let url = `${BASE_URL}triallocation/${id}/rep`;

  return new Observable((subscriber) => {
    NetworkService.patch(url, repInfoPayload).then(
      (response: any) => {
        subscriber.next(response);
        subscriber.complete();
      },
      (error) => {
        subscriber.error(error);
        console.log("Error while updating repInfo", error);
      }
    );
  });
};

import { createSlice } from "@reduxjs/toolkit";

// Define the TS type for the counter slice's state
export interface TrialsState {
  trialRequest: any;
}

// Define the initial value for the slice state
const initialState: TrialsState = {
  trialRequest: null,
};
export const trialsSlice = createSlice({
  name: "trials",
  initialState,
  reducers: {
    setTrialRequest: (state, action) => {
      state.trialRequest = action.payload;
    },
    resetTrialStates: (state) => {
      state = initialState;
    },
  },
});

// Export the generated action creators for use in components
export const { setTrialRequest, resetTrialStates } = trialsSlice.actions;

// Export the slice reducer for use in the store configuration
export default trialsSlice.reducer;

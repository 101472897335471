import { Observable } from "rxjs";
import NetworkService from "../network/networkService";
import { AgTechQueryParam } from "../enums";
import { helperFunctions } from "../helper";
import { ITrial, ITrialAllResponse } from "../models/ITrial.model";

const BASE_URL = "https://apidv.digitalag.farm/trialmaster/v1/";

export const getTrials = (
  queryParams?: Array<{
    key: AgTechQueryParam;
    value: string[];
  }>
): Observable<ITrialAllResponse> => {
  let url = `${BASE_URL}trial`;    
  for (let param of queryParams || []) {
    url = helperFunctions.addQueryParam(url, param.key, param.value);
  }
  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (res: unknown) => {
        if (res) {
          const response = res as unknown as ITrialAllResponse;
          subscriber.next(response);
        }
        subscriber.complete();
      },
      (error: Error) => {
        subscriber.error(error);
        console.log("Error while fetching user data", error);
      }
    );
  });
};

export const getContentLengthForTrial = (queryParams?: Array<{
  key: AgTechQueryParam;
  value: string[];
}>): Observable<number> => {
  let url = `${BASE_URL}trial`;
  
  for (let param of queryParams || []) {
    url = helperFunctions.addQueryParam(url, param.key, param.value);
  }
 
  return new Observable((subscriber) => {
    NetworkService.head(url)
      .then((res: any) => {
        subscriber.next(res?.contentLength ?? 0);
        subscriber.complete();
      })
      .catch((error: Error) => {
        subscriber.error(error);
        subscriber.next(0);
        console.log('Error while fetching crops master data:', error);
      });
  });
};

export const getTrialById = (
  id?: string,
  queryParams?: Array<{
    key: AgTechQueryParam;
    value: string[];
  }>
): Observable<ITrial> => {
  let url = `${BASE_URL}trial/${id}`;
  for (let param of queryParams || []) {
    url = helperFunctions.addQueryParam(url, param.key, param.value);
  }
  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (res: unknown) => {
        if (res) {
          const response = res as unknown as ITrial;
          subscriber.next(response);
        }
        subscriber.complete();
      },
      (error: Error) => {
        subscriber.error(error);
        console.log("Error while fetching trial data", error);
      }
    );
  });
};

export const getTrialsNextPage = (
  nextPageURI: string
): Observable<ITrialAllResponse> => {
  let url = `${BASE_URL}${nextPageURI}`;

  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (res) => {
        if (res) {
          const trialResponse = res as unknown as ITrialAllResponse;
          subscriber.next(trialResponse);
        }
        subscriber.complete();
      },
      (error: any) => {
        subscriber.error(error);
        console.log("Error while fetching Locations master data", error);
      }
    );
  });
};
export const createTrial = (requestPayload: any): Observable<any> => {
  let url = `${BASE_URL}trial`;

  return new Observable((subscriber) => {
    NetworkService.post(url, requestPayload).then(
      (trialResponse) => {
        if (trialResponse) {
          const trialsResponse = trialResponse as unknown as ITrialAllResponse;
          subscriber.next(trialsResponse);
        }
        subscriber.complete();
      },
      (error) => {
        subscriber.error(error);
        console.log("Error while creating a Trial", error);
      }
    );
  });
};

export const updateTrial = (
  requestPayload: any,
  trialId?: string
): Observable<any> => {
  let url = `${BASE_URL}trial/${trialId}`;

  return new Observable((subscriber) => {
    NetworkService.put(url, requestPayload).then(
      (res) => {
        const response = res as unknown as ITrial;
        subscriber.next(response);
        subscriber.complete();
      },
      (error) => {
        subscriber.error(error);
        console.log("Error while updating trialfield", error);
      }
    );
  });
};

export const updateTrialUsingPatch = (
  requestPayload: any,
  trialId?: string
): Observable<any> => {
  let url = `${BASE_URL}trial/${trialId}`;

  return new Observable((subscriber) => {
    NetworkService.patch(url, requestPayload).then(
      (res) => {
        const response = res as unknown as ITrial;
        subscriber.next(response);
        subscriber.complete();
      },
      (error) => {
        subscriber.error(error);
        console.log("Error while updating trialfield", error);
      }
    );
  });
};

export const deleteTrial = (trialId: string): Observable<any> => {
  let url = `${BASE_URL}trial/${trialId}`;

  return new Observable((subscriber) => {
    NetworkService.delete(url).then(
      (response) => {
        subscriber.next(response);
        subscriber.complete();
      },
      (error) => {
        subscriber.error(error);
        console.log("Error while updating trialfield", error);
      }
    );
  });
};

export const addTrialProducts = (
  trialPayload: any,
  id?: string
): Observable<ITrial> => {
  let url = `${BASE_URL}trial/${id}/addproducts`;
  return new Observable((subscriber) => {
    NetworkService.put(url, trialPayload).then(
      (res) => {
        const response = res as unknown as ITrial;
        subscriber.next(response);
        subscriber.complete();
      },
      (error) => {
        subscriber.error(error);
        console.log("Error while updating field", error);
      }
    );
  });
};
export const updateTrialProducts = (
  RequestPayload: any,
  id?: string
): Observable<ITrial> => {
  let url = `${BASE_URL}trial/${id}/products`;
  return new Observable((subscriber) => {
    NetworkService.patch(url, RequestPayload).then(
      (res) => {
        const response = res as unknown as ITrial;
        subscriber.next(response);
        subscriber.complete();
      },
      (error) => {
        subscriber.error(error);
        console.log("Error while updating field", error);
      }
    );
  });
};

export const deleteProduct = (trialId: string, productId: string): Observable<any> => {
  let url = `${BASE_URL}trial/${trialId}/product/${productId}`;
  return new Observable((subscriber) => {
    NetworkService.delete(url).then(
      (response) => {
        subscriber.next(response);
        subscriber.complete();
      },
      (error) => {
        subscriber.error(error);
        console.log("Error while deleting  trial product", error);
      }
    );
  });
};

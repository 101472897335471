import { createSlice } from "@reduxjs/toolkit";
import { ILocationBookTrial } from "packages/lib-utils";

export interface LocationBookState {
  selectedTrial: ILocationBookTrial | null;
}
const initialState: LocationBookState = {
  selectedTrial: null,
};
export const LocationBookSlice = createSlice({
  name: "LocationBook",
  initialState,
  reducers: {
    setSelectedTrial: (state, action) => {
      state.selectedTrial = action.payload;
    },
    resetLocationBookState: () => {
      return initialState;
    },
  },
});

// Export the generated action creators for use in components
export const { setSelectedTrial, resetLocationBookState } =
  LocationBookSlice.actions;

// Export the slice reducer for use in the store configuration
export default LocationBookSlice.reducer;

import { ColorEnum } from "./ColorEnums";
import { ColorPalette } from "./types";

export const themeColors: ColorPalette = {
  font: {
    Primary: ColorEnum.DarkBlue,
    LightBlue: ColorEnum.LightBlue,
    Black: ColorEnum.Black,
    LightGray: ColorEnum.LightGray,
    Gray: ColorEnum.Gray,
    White: ColorEnum.White,
    DarkGray: ColorEnum.DarkGray,
    GraniteGray:ColorEnum.GraniteGray,
    Danger:ColorEnum.Danger,
    MediumGray:ColorEnum.MediumGray,
    SeaBlue: ColorEnum.SeaBlue,
    Grayish:ColorEnum.Grayish,
    Green: ColorEnum.Green,
    BluishCyan: ColorEnum.BluishCyan,
    Orange: ColorEnum.Orange,
    SkyBlue:ColorEnum.SkyBlue,
    DarkBlue:ColorEnum.DarkBlue,
  },
  background: {
    Primary: ColorEnum.DarkBlue,
    Blue: ColorEnum.Blue,
    LightBlue: ColorEnum.LightBlue,
    SeaBlue: ColorEnum.SeaBlue,
    LighSky: ColorEnum.LighSky,
    SeaSky: ColorEnum.SeaSky,
    Sky: ColorEnum.Sky,
    Yellow: ColorEnum.Yellow,
    Danger: ColorEnum.Danger,
    Gray: ColorEnum.Gray,
    DarkGray: ColorEnum.DarkGray,
    LightGray: ColorEnum.LightGray,
    Black: ColorEnum.Black,
    White: ColorEnum.White,
    GrayishWhite:ColorEnum.GrayishWhite,
    MediumGrayish:ColorEnum.MediumGrayish,
    Grayish:ColorEnum.Grayish,
    SkyBlue:ColorEnum.SkyBlue,
    DarkBlue:ColorEnum.DarkBlue,
    LightSkyBlue: ColorEnum.LightSkyBlue,
    LightYellow: ColorEnum.LightYellow
  },
  inputsBorder: {
    SilverGray: ColorEnum.SilverGray,
    Blue: ColorEnum.Blue,
    Primary: ColorEnum.DarkBlue,
    Danger:ColorEnum.Danger,
    MediumGray:ColorEnum.MediumGray,
    MediumGrayish:ColorEnum.MediumGrayish,
    Grayish:ColorEnum.Grayish,
    SeaBlue:ColorEnum.SeaBlue,
    LightSkyBlue:ColorEnum.LightSkyBlue,
  }
};

import { Observable } from "rxjs";
import NetworkService from "../network/networkService";
import {
  ITreatmentGroup,
  ITreatmentAllResponse,
} from "../models/ITreatment.model";
import * as _ from "lodash";
import { helperFunctions } from "../helper/helper";
import { AgTechQueryParam } from "../enums/AgTechQueryParams.enum";

const BASE_URL = "https://apidv.digitalag.farm/trialmasterdata/v1/";

export const getTreatments = (
  queryParams?: Array<{
    key: AgTechQueryParam;
    value: string[];
  }>
): Observable<ITreatmentAllResponse> => {
  let url = `${BASE_URL}TreatmentGroup`;
  for (let param of queryParams || []) {
    url = helperFunctions.addQueryParam(url, param.key, param.value);
  }
  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (res: unknown) => {
        if (res) {
          let response = res as unknown as ITreatmentAllResponse;
          const updatedTrtGroups = response.treatmentGroups.map((trtGroup) => ({
            ...trtGroup,
            treatmentNames: trtGroup.treatments
              .map((treatment) => treatment.name)
              .join(", "),
          }));
          response.treatmentGroups = updatedTrtGroups;
          subscriber.next(response);
        }
        subscriber.complete();
      },
      (error: Error) => {
        subscriber.error(error);
        console.log("Error while fetching treatment master data", error);
      }
    );
  });
};

export const getTreatmentsNextPage = (
  nextPageURI: string
): Observable<ITreatmentAllResponse> => {
  const url = `${BASE_URL}${nextPageURI}`;

  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (res) => {
        if (res) {
          const response = res as unknown as ITreatmentAllResponse;
          const updatedTrtGroups = response.treatmentGroups.map((trtGroup) => ({
            ...trtGroup,
            treatmentNames: trtGroup.treatments
              .map((treatment) => treatment.name)
              .join(", "),
          }));
          response.treatmentGroups = updatedTrtGroups;
          const treatments = response.treatmentGroups;
          const treatmentsData = { ...response, treatments: treatments };
          subscriber.next(treatmentsData);
        }
        subscriber.complete();
      },
      (error: any) => {
        subscriber.error(error);
        console.log("Error while fetching treatment master data", error);
      }
    );
  });
};

export const getContentLengthForTreatment = (
  queryParams?: Array<{
    key: AgTechQueryParam;
    value: string[];
  }>
): Observable<number> => {
  let url = `${BASE_URL}TreatmentGroup`;
  for (let param of queryParams || []) {
    url = helperFunctions.addQueryParam(url, param.key, param.value);
  }

  return new Observable((subscriber) => {
    NetworkService.head(url)
      .then((res: any) => {
        subscriber.next(res?.contentLength ?? 0);
        subscriber.complete();
      })
      .catch((error: Error) => {
        subscriber.error(error);
        subscriber.next(0);
        console.log("Error while fetching crops master data:", error);
      });
  });
};

export const createTreatments = (requestPayload: any): Observable<any> => {
  let url =
    "https://apidv.digitalag.farm/trialmasterdata/v1" + "/TreatmentGroup";
  return new Observable((subscriber) => {
    NetworkService.post(url, requestPayload).then(
      (response) => {
        subscriber.next(response);
        subscriber.complete();
      },
      (error) => {
        subscriber.error(error);
        console.log("Error while creating a treatment", error);
      }
    );
  });
};

export const updateTreatment = (
  treatmentPayload: any,
  treatmentId?: string
): Observable<any> => {
  const url =
    "https://apidv.digitalag.farm/trialmasterdata/v1" +
    "/TreatmentGroup/" +
    treatmentId;

  return new Observable((subscriber) => {
    NetworkService.put(url, treatmentPayload).then(
      (res) => {
        const response = res as unknown as ITreatmentGroup;
        const treatmentData = {
          ...response,
          treatmentNames: response?.treatments
            .map((treatment) => treatment.name)
            .join(", "),
        };
        subscriber.next(treatmentData);
        subscriber.complete();
      },
      (error) => {
        subscriber.error(error);
        console.log("Error while updating treatmentfield", error);
      }
    );
  });
};

export const deleteTreatment = (treatmentId?: number): Observable<any> => {
  const url =
    "https://apidv.digitalag.farm/trialmasterdata/v1" +
    "/TreatmentGroup/" +
    treatmentId;

  return new Observable((subscriber) => {
    NetworkService.delete(url).then(
      (response) => {
        subscriber.next(response);
        subscriber.complete();
      },
      (error) => {
        subscriber.error(error);
        console.log("Error while deletting treatmentfield", error);
      }
    );
  });
};

import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { helperFunctions } from '../helper';
import { ILoginLOLResponse } from '../models/ILoginLOLResponse.model';

const http: AxiosInstance = axios.create({
    baseURL: '',
    headers: {
        'Content-Type': 'application/json',
    },
});


// Interceptor for any REST Request
http.interceptors.request.use((request: any) => {
    request = prepareCommonRequest(request);
    return request;
  });
  
  const onSuccess = (response: AxiosResponse) => {
      if (response.config.method === 'head') {
        response.data = { contentLength: response['headers']['content-count']}; 
      }
      return Promise.resolve(response.data); 
  };
  
  const onError = (error: any) => {
    console.log('error is', error);
    const { config: originalReq, response } = error;
    console.log('originalReq is', originalReq, response);

    //throw error;
    // skip refresh token request, retry attempts to avoid infinite loops
    if (originalReq && !originalReq.isRetryAttempt && response && response.status === 401) {
      console.log('Unauthorized- Refreshing token');
      
      return response && response.data ? Promise.reject(response.data) : Promise.reject(error);    

      // return new Promise((resolve, reject) => {
      //   const existingToken: any = helperFunctions.getLOLToken();
      //     refreshLOLToken(existingToken).then(
      //       (newToken: any) => {
      //         originalReq.isRetryAttempt = true;
      //         //update authroization headre with new token
      //         originalReq.headers['Authorization'] = `Bearer ${newToken?.access_token}`;
      //         axios.request(originalReq).then(
      //           (dateResponse) => {
      //             resolve(dateResponse);
      //           },
      //           (error) => {
      //             console.error('Error while retrying request', error);
      //             reject(error);
      //             // This is the location to signout user -- redirect to sso login page
      //           },
      //         );
      //       },
      //       (error: any) => {
      //         console.error('Error while LOL refresh token', error);
      //         reject(error);
      //       },
      //     );
      // });
    } else {
      return response ? Promise.reject(response) : Promise.reject(error);    
    }
  }

http.interceptors.response.use(onSuccess, onError);

const NetworkService = {
    get: (url: string, responseType?: any, queryParams?: any) =>
        http({
            method: 'GET',
            url: url,
            params: queryParams,
            responseType: responseType,
        }),
    patch: (url: string, requestBody?: any, options: AxiosRequestConfig = {}) =>
        http({
            method: 'PATCH',
            url: url,
            data: requestBody,
            ...options,
        }),
    post: (url: string, requestBody: any, options: AxiosRequestConfig = {}) =>
        http({
            method: 'POST',
            url: url,
            data: requestBody,
            ...options,
        }),
    search: (url: string, requestBody: any, options: any) =>
        http({
            method: 'SEARCH',
            url: url,
            data: requestBody,
            ...options,
        }),
    put: (url: string, requestBody: any, options: AxiosRequestConfig = {}) =>
        http({
            method: 'PUT',
            url: url,
            data: requestBody,
            ...options,
        }),

    delete: (url: string, responseType?: any, queryParams?: any) =>
        http({
            method: 'DELETE',
            url: url,
            params: queryParams,
            responseType: responseType,
        }), 
    head: (url: string, responseType?: any, queryParams?: any) =>
        http({
              method: 'HEAD',
              url: url,
              params: queryParams,
              responseType: responseType,
          }), 
};


export default NetworkService;

const prepareCommonRequest = (request: any) => {
  if (request.options && request.options['withCredentials']) {
    request['withCredentials'] = true;
  }
  if (request.options && request.options['iscontentSet']) {
    delete request.headers['Content-Type'];
  } else if (request.options) {
    request.headers['Content-Type'] = request.options && request.options['Content-Type'];
  }
  const lolToken = helperFunctions.getLOLToken();
  if (lolToken) {
    request.headers['X-Api-Key'] = lolToken?.apiKey;
    request.headers['Authorization'] = `Bearer ${lolToken?.access_token}`;
    // request.headers['X-Api-Key'] = 'c1a3d1c6fe24490b9503c3157830b069';
    // request.headers['Authorization'] = `Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6Ino2SHA2M2tadVZzTFcyTjlINzUtck5oQktiTSIsInBpLmF0bSI6ImN4cDYifQ.eyJzY29wZSI6WyJBZ3RlY2guRlVMTCJdLCJjbGllbnRfaWQiOiJyc19jbGllbnRfZHNfYXp1cmUiLCJpc3MiOiJodHRwczovL3Nzb3FhLmxhbmRvbGFrZXNpbmMuY29tIiwiZm5hbWUiOiJEaXZ5YSIsImxuYW1lIjoiQXJvcmEiLCJhZzJhZyI6ImFyb3JhZDAxIiwiY29udGV4dCI6IkJMIiwic2l0ZV9pZCI6IldpbmZpZWxkIiwiYW5hbHl0aWNzX2lkIjoiODA0NTExIiwiZmVkX2lkIjoiREFyb3JhQGxhbmRvbGFrZXMuY29tIiwib3JnX25hbWUiOiJMYW5kIE9MYWtlcyIsInNlY3VyaXR5X3JvbGVzIjpbIkdHX0RhdGFzaWxvX1dGX1VTRVJfUUEiLCJHR19EYXRhc2lsb19XRl9BRE1JTl9RQSJdLCJhY2NvdW50IjoiYXJvcmFkMDEiLCJleHAiOjE3MzA3MDg4NjN9.sgpEbSfD5Uz3o12UF74H5iUHnEYauFF-6IjW3qOg55OV9yERUrhBTPyD4M7DjaP0rIqrxoeYeeMosFz6zKKFfzhRjU7V942kgmBalcKAfl38cdPj2wqRzJfb4Z5Siuba_bbxaSB-ekB2nz-XCBTkVjU4ANFwgQqt0oArOVYOlSqYom7PMSdYneKLxigeagyuHwLyrU_u7L0hlddfkIsSmEm1r-uKrrMn1g0DVwu0-N8Ei6MEdiLZcM1CVzDzeKvUJdeJpbE0viBDvUGjaE6sH-RZ6EmyNAthYAqJKsRmM1gCirwuuDd7Mbgk-ZV16YWnxB65U32aCHHgZVmG_HTfwg`;
  }
  return request;
};
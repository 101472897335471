export const AppConstants = {
  LOLLoginSessionKey: "LOLLoginSessionKey",
  appUrls:{
    USERS:'/masterdata/users',
    TEAMS:'/masterdata/teams',
    TREATMENTS:'/masterdata/treatments',
    CROPS:'/masterdata/crops',
    PRODUCTS:'/masterdata/products',
    LOCATIONS:'/masterdata/locations',
    SEASON:'/masterdata/seasons',
    INVENTORY:'/main/inventory',
    DASHBOARD:'/main/dashboard',
    TRIALS:'/main/trials',
    EXPERIMENT_TRIAL_LOCATIONS:'/main/experiments/triallocations',
    EXPERIMENT_TRIAL_LOCATIONS_FIELDMAP:"/main/experiments/triallocations/fieldmap",
    EXPERIMENT_TRIAL_LOCATIONS_CREATE_LOCATION:"/main/experiments/triallocations/createlocation",
    EXPERIMENT_MAPPING_SHEET:'/main/experiments/mappingSheets',
    EXPERIMENT_MAPPING_SHEET_TEAM:'/main/experiments/mappingSheets',
    EXPERIMENT_TRIAL_LOCATIONS_BOOK:'main/experiments/triallocations/locationbook'
  },
  pages: [
    { title: "Master Data", path: "/masterdata" },
    { title: "Users", path: "/masterdata/users" },
    { title: "Crops", path: "/masterdata/crops" },
    { title: "Teams", path: "/masterdata/teams" },
    { title: "Treatments", path: "/masterdata/treatments" },
    { title: "Products", path: "/masterdata/products" },
    { title: "Locations", path: "/masterdata/locations" },
    { title: "Seasons", path: "/masterdata/seasons" },
  ],
  COMMON_TEXT: {
    SEARCH_HINT_TEXT: "Press enter to search",
    DELETE_CONFIRM_TITLE: "Delete Confirmation",
    DELETE_CONFIRM_DESCRIPTION: "Are you sure you want to delete?",
    CONFIRM_DIALOG_YES: "Yes",
    CONFIRM_DIALOG_NO: "No",
    SHOW_ALL: "Show All",
    SEARCH_BY_USER_NAME: "Search By User Name",
    DISCARD_CONFIRM_DESCRIPTION:
      "All changes will be lost if you discard. Do you want to continue?",
    DISCARD_CONFIRM_TITLE: "Discard Confirmation",
  },
  APP_DEFAULT_FILTER:{
     SELECTED_CROP:"CORN"
  },

  SYSTEM_ADMIN_ID: 1,
};

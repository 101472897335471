import { Observable } from "rxjs";
import NetworkService from "../network/networkService";
import * as _ from "lodash";
import { helperFunctions } from "../helper/helper";
import { AgTechQueryParam } from "../enums/AgTechQueryParams.enum";
import { ILocationAllResponse } from "../models/ILocation.model";
export const BASE_URL = "https://apidv.digitalag.farm/trialmasterdata/v1";
export const getLocations = (
  queryParams?: Array<{
    key: AgTechQueryParam;
    value: string[];
  }>
): Observable<ILocationAllResponse> => {
  let url = `${BASE_URL}/Location`;

  for (let param of queryParams || []) {
    url = helperFunctions.addQueryParam(url, param.key, param.value);
  }
  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (locationResponse: unknown) => {
        if (locationResponse) {
          const locationsResponse =
            locationResponse as unknown as ILocationAllResponse;

          subscriber.next(locationsResponse);
        }
        subscriber.complete();
      },
      (error: any) => {
        subscriber.error(error);
        console.log("Error while fetching Locations master data", error);
      }
    );
  });
};

export const getLocationsNextPage = (
  nextPageURI: string
): Observable<ILocationAllResponse> => {
  let url = `${BASE_URL}${nextPageURI}`;

  return new Observable((subscriber) => {
    NetworkService.get(url).then(
      (locationResponse) => {
        if (locationResponse) {
          const locationsResponse =
            locationResponse as unknown as ILocationAllResponse;

          subscriber.next(locationsResponse);
        }

        subscriber.complete();
      },
      (error: any) => {
        subscriber.error(error);
        console.log("Error while fetching Locations master data", error);
      }
    );
  });
};

export const createLocation = (requestPayload: any): Observable<any> => {
  let url = `${BASE_URL}/Location`;

  return new Observable((subscriber) => {
    NetworkService.post(url, requestPayload).then(
      (locationResponse) => {
        if (locationResponse) {
          const locationsResponse =
            locationResponse as unknown as ILocationAllResponse;
          subscriber.next(locationsResponse);
        }
        subscriber.complete();
      },
      (error) => {
        subscriber.error(error);
        console.log("Error while creating a Location", error);
      }
    );
  });
};

export const getContentLengthForLocation = (
  queryParams?: Array<{
    key: AgTechQueryParam;
    value: string[];
  }>
): Observable<number> => {
  let url = `${BASE_URL}/Location`;
  for (let param of queryParams || []) {
    url = helperFunctions.addQueryParam(url, param.key, param.value);
  }

  return new Observable((subscriber) => {
    NetworkService.head(url)
      .then((res: any) => {
        subscriber.next(res?.contentLength ?? 0);
        subscriber.complete();
      })
      .catch((error: Error) => {
        subscriber.error(error);
        subscriber.next(0);
        console.log("Error while fetching crops master data:", error);
      });
  });
};

export const updateLocation = (
  seasonPayload: any,
  locationId?: string
): Observable<any> => {
  let url = `${BASE_URL}/Location/${locationId}`;

  return new Observable((subscriber) => {
    NetworkService.patch(url, seasonPayload).then(
      (response) => {
        subscriber.next(response);
        subscriber.complete();
      },
      (error) => {
        subscriber.error(error);
        console.log("Error while updating seasonfield", error);
      }
    );
  });
};

import { createSlice } from '@reduxjs/toolkit';
import { ITrialLocation } from 'packages/lib-utils/src/models/ITrialLocation.model';
import React from 'react'


export interface TrialsState {
  trialLocationRequest:any,
}
const initialState: TrialsState = {
  trialLocationRequest:null,
 
};
export const trialLocationSlice = createSlice({
  name: "trialLocation",
  initialState,
  reducers: {
    setTrialLocationRequest: (state, action) => {
      state.trialLocationRequest = action.payload;
    },
    resetTrialLocationStates: (state) => {
      state = initialState;
    },
  },
});

// Export the generated action creators for use in components
export const { setTrialLocationRequest, resetTrialLocationStates } = trialLocationSlice.actions;

// Export the slice reducer for use in the store configuration
export default trialLocationSlice.reducer;



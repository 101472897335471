
export enum AgTechQueryParam {
  SeasonName = "Name",
  StateIds = "StateIds",
  CountryIds = "CountryIds",
  PageSize = "PageSize",
  UserGroupName = "UserGroupName",
  Status = "Status",
  OrderBy = "OrderBy",
  SortOrder = "SortOrder",
  Brand = "Brand",
  Crops = "Crop",
  Name = "Name",
  Team = "Team",
  IsTreatmentExist = "IsTreatmentExist",
  RandomizationType = "RandomizationType",
  minRM = "minRM",
  maxRM = "maxRM",
  crop = "crop",
  season = "season",
  Season = "Season",
  TrialName = "TrialName",
}
